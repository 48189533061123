import * as PathHelpers from "@/lib/Listing/PathHelpers"
import omit from "lodash/omit"
import { i18n } from "@/lib/vue/plugins/i18n"
import store from "@/store/modules/filter.module.js"

const market = i18n.t("market")

function priceRange(lowValue, highValue) {
  return `${market.currency.sign}${lowValue} - ${market.currency.sign}${highValue}`
}

function sizeValueFor(size) {
  let size_caption = market.filters.sizes[size]
  if (["XL", "S"].includes(size))
    size_caption = `${size == "XL" ? ">" : "<"} ${size_caption.split("-")[+(size == "S")]}`

  return {
    caption: size_caption + market.units.length.short,
    value: market.filters.sizes[size],
  }
}

const ListingFilters = {
  sizes: [
    {
      title: "Extra Large",
      short: "XL",
      sid: "size_extra_large",
    },
    {
      title: "Large",
      short: "L",
      sid: "size_large",
    },
    {
      title: "Medium",
      short: "M",
      sid: "size_medium",
    },
    {
      title: "Small",
      short: "S",
      sid: "size_small",
    },
  ].map((value) => ({ ...value, ...sizeValueFor(value.short) })),
  orientations: ["landscape", "portrait", "square"],
  artwork_statuses: [
    { title: "Bluethumb", label: "Bluethumb", value: "primary" },
    { title: "Podium", label: "Podium", value: "secondary" },
  ],
  categories: {
    "All Art": [],
    Paintings: [
      "oil",
      "acrylic",
      "watercolour",
      "ink",
      "gouache",
      "mixed-media",
      "airbrush",
      "spray-paint",
      "stencil",
      "resin",
    ],
    Photography: ["photograph"],
    Drawings: ["pencil", "ink", "pastel", "drawing", "charcoal"],
    Sculpture: ["sculpture", "ceramic", "bronze", "metal", "fibre", "glass", "wood", "weaving"],
    Printmaking: [
      "lithograph",
      "linocut-print",
      "screenprinting",
      "giclee",
      "etching",
      "woodcut",
      "engraving",
      "reproduction-print",
      "collagraph",
      "monotype",
    ],
    Digital: ["digital"],
    "Other media": ["other-media"],
  },
  locations: [
    { title: "VIC", value: "VIC" },
    { title: "NSW", value: "NSW" },
    { title: "ACT", value: "ACT" },
    { title: "TAS", value: "TAS" },
    { title: "QLD", value: "QLD" },
    { title: "WA", value: "WA" },
    { title: "SA", value: "SA" },
    { title: "NT", value: "NT" },
  ],
  prices: [
    { title: "All", value: "0-99999" },
    { title: `Over ${market.currency.sign}5,000`, value: "5000-99999" },
    { title: priceRange("5,000", "1,000"), value: "1000-5000" },
    { title: priceRange("1,000", "500"), value: "500-1000" },
    { title: `Under ${market.currency.sign}500`, value: "0-500" },
  ],
  sortLabelMap: () => ({
    ...(PathHelpers.currentCompetitionPath() ? { "latest-entry": "Latest Art Prize Entry" } : {}),
    ...(PathHelpers.featuredArtPath() ? { "latest-exclusive": "Most Relevant" } : {}),
    relevant: "New Art",
    ...(PathHelpers.staffPicksPath() ? { featured_at: "Most Relevant" } : {}),
    popular: "Popular",
    "price-low": "Price: Low to High",
    "price-high": "Price: High to Low",
    recently_uploaded: "Feed",
    display_order: "Display Order",
  }),
  sortKeys() {
    return Object.keys(omit(this.sortLabelMap(), ["display_order"]))
  },
  sortOptions() {
    return Object.entries(omit(this.sortLabelMap(), ["display_order"])).map((option) => ({
      title: option[1],
      value: option[0],
    }))
  },
  refiningLabelMap: {
    collections: "Subject",
    art_styles: "Style",
    artwork_status: "Artwork Status",
    categories: "Categories",
    mediums: "Medium",
    sizes: "Size",
    orientations: "Orientation",
    prices: "Price",
    colors: "Colour",
    locations: "Artist Location",
    displays: "Display",
  },
}

export const defaultSortOption = () => {
  if (PathHelpers.currentCompetitionPath()) return "popular"
  if (PathHelpers.staffPicksPath()) return "featured_at"
  if (PathHelpers.featuredArtPath()) return store.state.userArtist ? "recently_uploaded" : "latest-exclusive"

  return "relevant"
}

export const sizeToMetaId = (val) => {
  return ListingFilters.sizes.find((size) => size.value == val).sid
}

export default ListingFilters
export const categories = ListingFilters.categories
